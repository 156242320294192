import { ProductProjection } from '@commercetools/platform-sdk';
import { fold } from 'fp-ts/Either';
import { pipe } from 'fp-ts/function';
import {
  IContentPage,
  IContributors,
  IDashHudsonUgc,
  IHeader,
  IHero,
  IHeroCarousel,
  IHeroHalf,
  IOutfit,
  IPicture,
  IProductGrid,
  IProductMosaic,
  IProductRail,
  IRichTextBlock,
  ISizeGuideTabs,
  ISplitHero,
  IStories,
  IStoriesOverlay,
  ISubscribePage,
  ITwoColumnsListing,
} from 'generated/contentful';
import { reportTypeErrors } from 'lib/reportTypeErrors';
import returnValidModel from 'lib/returnValidModel';
import toContributors from 'models/contentful/contributors/serializers';
import toDashHudsonUGC from 'models/contentful/dashHudsonUGC/serializers';
import toHeader from 'models/contentful/header/serializers';
import toHero from 'models/contentful/hero/serializers';
import toHeroCarousel from 'models/contentful/heroCarousel/serializers';
import toHeroHalf from 'models/contentful/heroHalf/serializers';
import toOutfit from 'models/contentful/outfit/serializers';
import toPicture from 'models/contentful/picture/serializers';
import toProductGrid from 'models/contentful/productGrid/serializers';
import toProductMosaic from 'models/contentful/productMosaic/serializers';
import toProductRail from 'models/contentful/productRail/serializers';
import toRichText from 'models/contentful/richText/serializers';
import toSizeGuideTabs from 'models/contentful/sizeGuideTabs/serializers';
import toSplitHero from 'models/contentful/splitHero/serializers';
import toStories from 'models/contentful/stories/serializers';
import toStoriesOverlay from 'models/contentful/storiesOverlay/serializers';
import toSubscribe from 'models/contentful/subscribe/serializers';
import toTwoColumnsListing from 'models/contentful/twoColumnsListing/serializers';
import {
  contentPageModel,
  ContentPageModel,
  ContentPageSlot,
} from 'models/contentPages/types';

type ToContentPage = (args: {
  contentPage: IContentPage;
  products: Record<string, ProductProjection>;
  productsByCategory: Record<string, string[]>;
  locale: string;
}) => ContentPageModel | null;

export const toContentPage: ToContentPage = ({
  contentPage,
  products,
  productsByCategory,
  locale,
}) => {
  const slots = contentPage.fields.slots
    ?.map(content => {
      if (!content?.sys?.contentType) return null;
      switch (content.sys.contentType.sys.id) {
        case 'contributors':
          return toContributors({ content: content as IContributors, locale });
        case 'dashHudsonUGC':
          return toDashHudsonUGC({
            content: content as IDashHudsonUgc,
            locale,
          });
        case 'hero':
          return toHero({ content: content as IHero, locale });
        case 'heroHalf':
          return toHeroHalf({ content: content as IHeroHalf, locale });
        case 'heroCarousel':
          return toHeroCarousel({ content: content as IHeroCarousel, locale });
        case 'splitHero':
          return toSplitHero({ content: content as ISplitHero, locale });
        case 'outfit':
          return toOutfit({
            content: content as IOutfit,
            locale,
            products,
          });
        case 'picture':
          return toPicture({ content: content as IPicture, locale });
        case 'productGrid':
          return toProductGrid({
            content: content as IProductGrid,
            locale,
            products,
            productsByCategory,
          });
        case 'productMosaic':
          return toProductMosaic({
            content: content as IProductMosaic,
            locale,
            products,
          });
        case 'productRail':
          return toProductRail({
            content: content as IProductRail,
            locale,
            products,
          });
        case 'richTextBlock':
          return toRichText({ content: content as IRichTextBlock, locale });
        case 'stories':
          return toStories({ content: content as IStories, locale });
        case 'storiesOverlay':
          return toStoriesOverlay({
            content: content as IStoriesOverlay,
            locale,
          });
        case 'subscribePage':
          return toSubscribe({ content: content as ISubscribePage, locale });
        case 'twoColumnsListing':
          return toTwoColumnsListing({
            content: content as ITwoColumnsListing,
            locale,
          });
        case 'header':
          return toHeader({ content: content as IHeader, locale });
        case 'sizeGuideTabs':
          return toSizeGuideTabs({
            content: content as ISizeGuideTabs,
            locale,
          });
        default:
          return null;
      }
    })
    .filter(block => block !== null) as ContentPageSlot[];

  const page: ContentPageModel = {
    header: contentPage.fields.header
      ? toHeader({ content: contentPage.fields.header, locale })
      : null,
    browserTitle: contentPage.fields.browserTitle ?? '',
    metaTitle: contentPage.fields.metaTitle ?? '',
    metaDescription: contentPage.fields.metaDescription ?? '',
    metaImage: contentPage.fields.metaImage?.fields.file ?? null,
    displayMode: contentPage.fields.display,
    darkMode: contentPage.fields.overlayDarkMode ?? true,
    slots,
  };

  return pipe(
    contentPageModel.decode(page),
    fold(
      reportTypeErrors({
        id: contentPage.fields.slug,
        model: 'Content Pages',
        fallback: null,
      }),
      returnValidModel
    )
  );
};
