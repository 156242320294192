import { Image, ProductVariant } from '@commercetools/platform-sdk';
import {
  AttributeName,
  getBooleanAttribute,
  getLocalisedEnumAttribute,
} from 'models/attributes/serializers';
import { Language } from 'models/locales/types';
import { ProductListingModel } from 'models/productListing/types';

const IMAGE_SUFFIX_PATTERN = /(\_\d)\.[a-z]+(?!\.)(?:$|\?)/;

export const deriveListingSwatches = (
  variants: ProductVariant[] = [],
  language: Language
): ProductListingModel['swatches'] => {
  return variants.reduce<ProductListingModel['swatches']>((acc, curr) => {
    const swatch = getLocalisedEnumAttribute(
      curr.attributes,
      AttributeName.Swatch,
      language
    );
    acc[`${process.env.NEXT_PUBLIC_IMGIX_URL}/swatches/${swatch.key}.jpg`] =
      swatch.label;
    return acc;
  }, {});
};

export const sortImages = (
  images: Image[] = [],
  productImageToDisplay: 'model' | 'stillLife' = 'model'
): Image[] => {
  const imageMap: Record<string, Image> = {};

  for (const image of images) {
    const key = image.url.match(IMAGE_SUFFIX_PATTERN)?.[1] ?? image.url;

    imageMap[key] = image;
  }

  const { _0: stillLife, _1: model, _2: back, ...rest } = imageMap;

  const imagesOrderedByDisplaySetting: Image[] = [];

  if (productImageToDisplay === 'stillLife') {
    imagesOrderedByDisplaySetting.push(stillLife);
    imagesOrderedByDisplaySetting.push(model);
    imagesOrderedByDisplaySetting.push(back);
  } else {
    if (model) {
      imagesOrderedByDisplaySetting.push(model);
      imagesOrderedByDisplaySetting.push(back);
      imagesOrderedByDisplaySetting.push(stillLife);
    } else {
      imagesOrderedByDisplaySetting.push(stillLife);
      imagesOrderedByDisplaySetting.push(back);
    }
  }

  const sortedImages = imagesOrderedByDisplaySetting
    .concat(Object.values(rest))
    .filter(image => image !== undefined);

  if (sortedImages.length === 0) {
    return images;
  }

  return sortedImages.slice(0, 2);
};

export const isAllVariantsPreorder = (
  variants: ProductVariant[] = []
): boolean => {
  return variants.every(variant =>
    getBooleanAttribute(variant.attributes, AttributeName.Preorder, false)
  );
};
