import type {
  ProductProjection,
  ProductVariant,
} from '@commercetools/platform-sdk';
import { fold } from 'fp-ts/Either';
import { pipe } from 'fp-ts/function';
import { filter, findFirst } from 'fp-ts/lib/Array';
import * as O from 'fp-ts/lib/Option';
import { getApiLocale } from 'lib/locale';
import { ApiLocale } from 'lib/locale/types';
import { reportTypeErrors } from 'lib/reportTypeErrors';
import returnValidModel from 'lib/returnValidModel';
import translate from 'lib/translate';
import zeroable from 'lib/zeroable';
import {
  AttributeName,
  getBooleanAttribute,
  getLocalisedEnumAttribute,
  getLocalisedEnumSetAttribute,
  getTextArrayAttribute,
} from 'models/attributes/serializers';
import {
  ProductListingModel,
  productListingModel,
} from 'models/productListing/types';
import {
  deriveListingSwatches,
  isAllVariantsPreorder,
  sortImages,
} from 'models/productListing/utilities';
import { PriceModel } from 'models/variants/types';
import { deriveImages, derivePrice } from 'models/variants/utilities';

type ToProductMap = (args: {
  products: ProductProjection[];
  locale: string;
}) => Record<string, ProductListingModel>;

type ToProductListing = (args: {
  product: ProductProjection;
  locale: string;
  categoryKey?: string;
  categoryId?: string;
  productImageToDisplay?: 'model' | 'stillLife';
  allowOutOfStock?: boolean;
}) => ProductListingModel | null;

export const isAvailableProduct = (
  variant: ProductVariant,
  locale: ApiLocale
): boolean => {
  // This really should not be hard coded it will break when we move to
  // having multiple channels. This could be mitigated by using stores to ensure
  // only the channel we're interested in is returned in productProjection
  // responses
  if (!variant.availability?.channels) {
    return false;
  }

  const includedInStores = getLocalisedEnumSetAttribute(
    variant.attributes,
    AttributeName.IncludedStores,
    locale.language
  ).map(store => store.key);

  return (
    Object.values(variant.availability.channels).some(
      channel => channel.isOnStock === true
    ) &&
    getBooleanAttribute(variant.attributes, AttributeName.Available, true) &&
    includedInStores.includes(locale.store)
  );
};

export const selectAvailableVariants = (
  variants: ProductVariant[],
  locale: ApiLocale
): ProductVariant[] =>
  pipe(
    variants,
    filter((variant: ProductVariant) => isAvailableProduct(variant, locale))
  );

export const chooseDisplayVariantForSearch = (
  availableVariants: ProductVariant[]
) => {
  return pipe(
    availableVariants,
    findFirst(({ isMatchingVariant }) => isMatchingVariant === true),
    O.fold(
      () => availableVariants[0] ?? null,
      variant => variant
    )
  );
};

export const isVariantForCategory = (
  { attributes }: ProductVariant,
  categoryKey: string
): boolean =>
  getTextArrayAttribute(
    attributes,
    AttributeName.DefaultForCategoryKeys
  ).includes(categoryKey);

export const chooseDisplayVariantForCategory = (
  availableVariants: ProductVariant[],
  categoryKey: string
) =>
  pipe(
    availableVariants,
    findFirst(variant => isVariantForCategory(variant, categoryKey)),
    O.fold(
      () => availableVariants[0] ?? null,
      variant => variant
    )
  );

export const toProductListing: ToProductListing = ({
  product,
  locale,
  categoryId = '',
  categoryKey = '',
  productImageToDisplay = 'model',
  allowOutOfStock = false,
}) => {
  const apiLocale = getApiLocale(locale);
  const language = apiLocale.language;
  const t = translate(language);

  const availableVariants = allowOutOfStock
    ? [product.masterVariant, ...product.variants]
    : selectAvailableVariants(
        [product.masterVariant, ...product.variants],
        apiLocale
      );

  // currently there is no test that covers this
  // conditional. If more time were available, variant
  // should a parameter to toProductListing allowing
  // the caller to choose the right variant for its sceanrio
  // eliminating any need for conditional statements.
  const displayVariant = !categoryKey
    ? chooseDisplayVariantForSearch(availableVariants)
    : chooseDisplayVariantForCategory(availableVariants, categoryKey);

  if (displayVariant === null) {
    return null;
  }

  const swatchKey = getLocalisedEnumAttribute(
    displayVariant.attributes,
    AttributeName.Swatch,
    language
  ).key;

  const model: ProductListingModel = {
    url: `/${locale}/p/${product.slug.en}/${swatchKey}`,
    name: t(product.name),
    categoryOrderHint: zeroable(
      Number(product.categoryOrderHints?.[categoryId])
    ),
    images: deriveImages(
      sortImages(displayVariant.images, productImageToDisplay)
    ),
    price: derivePrice(displayVariant, apiLocale.country) as PriceModel,
    sku: displayVariant.sku as string,
    key: product.key as string,
    swatches: deriveListingSwatches(availableVariants, language),
    gender: getLocalisedEnumAttribute(
      displayVariant.attributes,
      AttributeName.Gender,
      language
    ).label,
    tag: getLocalisedEnumAttribute(
      displayVariant.attributes,
      AttributeName.Tag,
      language
    ).label,
    preorder: isAllVariantsPreorder(availableVariants),
  };

  return pipe(
    productListingModel.decode(model),
    fold(
      reportTypeErrors({
        id: product.masterVariant.sku as string,
        model: 'Product Listing',
        fallback: null,
      }),
      returnValidModel
    )
  );
};

export const toProductMap: ToProductMap = ({ products, locale }) =>
  products
    .map(product => toProductListing({ product, locale }))
    .filter((product): product is ProductListingModel => product !== null)
    .reduce<Record<string, ProductListingModel>>((acc, curr) => {
      acc[curr.key] = curr;
      return acc;
    }, {});
